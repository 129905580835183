import axios from "axios";
import { OpenAPI } from "../services/core/OpenAPI";

export let isRefreshing: boolean = false;

export function createAxiosRefreshInterceptor() {
    console.log("axios interceptor created");

    const interceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response.status !== 401) {
                createAxiosRefreshInterceptor();

                return Promise.reject(error);
            }

            axios.interceptors.response.eject(interceptor);

            if (!isRefreshing) {
                isRefreshing = true;

                try {
                    await axios.post(
                        `${OpenAPI.BASE}/authorization/refresh`,
                        {},
                        {
                            withCredentials: true,
                        }
                    );

                    isRefreshing = false;
                } catch {
                    isRefreshing = false;

                    return Promise.reject(error);
                }
            }

            while (isRefreshing) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }

            createAxiosRefreshInterceptor();

            return axios(error.response.config);
        }
    );
}

createAxiosRefreshInterceptor();
